<template>
  <app-list-view
    server-side
    show-group-by
    app="authentication"
    model="user"
    api-url="auth/user/"
    :title="$t('menu.user')"
    :createTo="{ name: 'userCreate' }"
    :editTo="{ name: 'userEdit' }"
    :headers="headers"
  >
    <template v-slot:item.is_active="{ item }">
      <v-icon :color="item.is_active ? 'green' : 'grey lighten-2'">
        mdi-check
      </v-icon>
    </template>
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'userList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.username'), value: 'username' },
        { text: this.$t('fields.firstName'), value: 'contact_id.first_name' },
        { text: this.$t('fields.lastName'), value: 'contact_id.last_name' },
        {
          text: this.$t('fields.accessRight'),
          value: 'group_id.name',
          hideFilter: true
        },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.customerCompany'),
          value: 'customer_company_id.name'
        },
        {
          text: this.$t('fields.active'),
          value: 'is_active',
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  }
}
</script>
